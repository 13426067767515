<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import { useCustomerStore } from '@/core/store/CustomerStore'
import { useConfirm } from "primevue/useconfirm"
import { slugify, getRandomId } from '@/core/var/tools'

const CoreRouter = inject('CoreRouter');
const confirm = useConfirm()
const toast = useToast()
const loader = ref(false)

//bank details

const banknames = ref([
    {name: "Credit Suisse AG", value: "Credit Suisse AG", hostid: "CSEBICS"},
    {name: "Migros Bank AG", value: "Migros Bank AG", hostid: "MGBEBICS"},
    {name: "PostFinance AG", value: "PostFinance AG", hostid: "PFEBICS"},
    {name: "UBS AG", value: "UBS AG", hostid: "EBXUBSCH"},
    {name: "Raiffeisenbank Zürcher Oberland", value: "Raiffeisenbank Zürcher Oberland", hostid: "RAIFCHEC"},
    {name: "Raiffeisenbank Zürich", value: "Raiffeisenbank Zürich", hostid: "RAIFCHEC"},
    {name: "Raiffeisenbank St. Gallen", value: "Raiffeisenbank St. Gallen", hostid: "RAIFCHEC"},
    {name: "Zürcher Kantonalbank", value: "Zürcher Kantonalbank", hostid: "ZKBKCHZZ"},
])

const keyversions = ref([
    {name: "E002", value: "E002"},
    {name: "X002", value: "X002"},
    {name: "A005", value: "A005"},
    {name: "A006", value: "A006"}
])
const protocols = ref([
    {name: "EBICS 3.0 (H005)", value: "H005"},
    {name: "EBICS 2.5 (H004)", value: "H004"},
    {name: "EBICS 2.4 (H003)", value: "H003"}
])
const transaction_ranges = ref([
    {name: "Letzte 30 Tage", value: "30"},
    {name: "Letzte 60 Tage", value: "60"},
    {name: "Letzte 90 Tage", value: "90"},
    {name: "Letzte 180 Tage", value: "180"},
    {name: "Letzte 365 Tage", value: "365"}
])

const transaction_range = ref("30")
const transactions = ref([])
const transaction = ref({})
const matching = ref({})
const unmatched_transactions = ref([])
const filter_transactions = ref({global: {value: '',matchMode: FilterMatchMode.CONTAINS}})
const filter_unmatched_transactions = ref({global: {value: '',matchMode: FilterMatchMode.CONTAINS}})
const expandedRows = ref([])
const multiple_invoice_amounts = ref({})
const multiple_invoice_overpaid = ref(0)

const bankname = ref()
const keyversion = ref()
const protocol = ref()
const passphrase = ref()
const hostid = ref()
const url = ref()
const partnerid = ref()
const userid = ref()
const banks = ref([])
const username = ref()

const search_first_name = ref('')
const search_last_name = ref('')
const search_city = ref('')
const search_zip = ref('')
const search_street = ref('')
const search_reference = ref('')

const search_results = ref([])
const invoice_search_results = ref([])

const createBankDialog = ref(false)
const infoSnappyBankingDialog = ref(false)
const viewTransactionDialog = ref(false)
const viewMatchingDialog = ref(false)
const view_unmatched_transactions_slider = ref(false)
const view_invoice_search_slider = ref(false)
const filter_banks = ref({global: {value: '',matchMode: FilterMatchMode.CONTAINS}})

const infoSnappyBanking = ref([
    {
        title: 'EBICS-Vertrag',
        subtitle: 'Beantragen Sie EBICS bei Ihrer Bank',
        content: 'Rufen Sie Ihre Bank an und beantragen Sie EBICS für Ihr Bankkonto. Die Bank schickt Ihnen ein Formular, das Sie ausfüllen, unterschreiben und zurückschicken müssen.'
    },
    {
        title: 'Datenerfassung',
        subtitle: 'Nach Erhalt Ihrer Bankparameterdaten',
        content: 'Sie können nun Ihre Daten sorgfältig aus dem Datenblatt, das Ihnen die Bank zusendet, eingeben. Sie können diese Daten in Snappy Banking eingeben und dann auf INI/HIA-Brief erstellen klicken.'
    },
    {
        title: 'Senden Sie Ihren INI/HIA-Brief',
        subtitle: 'Drucken und versenden Sie Ihren INI/HIA-Brief',
        content: 'Sie können nun Ihren INI/HIA-PDF-Brief ausdrucken, unterschreiben und an Ihre Bank senden. Sie erhalten dann eine Bestätigung, dass die Schlüssel für Ihre EBICS-Schnittstelle aktiviert wurden. Nun können Sie Ihre Schlüssel in Snappy Banking aktivieren und sind startklar!'
    }
])

const get_base_data = () => {
    // Get Data
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-ebics-banks')
    .then(response => {
        banks.value = response.data
        console.log("banks", banks.value)
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-transactions')
    .then(response => {
        transactions.value = response.data
        console.log("transactions", transactions.value)
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-unmatched-transactions')
    .then(response => {
        unmatched_transactions.value = response.data
        console.log("unmatched", response.data)
    })
}
onMounted(() => {
    get_base_data()
})

const set_hostid = (e) => {
    console.log("dropdown", e)
    hostid.value = get_hostid(e.value)
}

const get_hostid = (name) => {
    let bank = banknames.value.find(bank => bank.value == name)
    return bank.hostid
}

const get_protocol = (value) => {
    let protocol = protocols.value.find(protocol => protocol.value == value)
    return protocol.name
}

const create_bank = () => {
    console.log("create bank")
    let payload = {
        bankname: bankname.value,
        hostid: hostid.value,
        protocol: protocol.value,
        url: url.value,
        partnerid: partnerid.value,
        username: username.value,
        userid: userid.value,
        passphrase: passphrase.value,
        keyversion: keyversion.value
    }
    console.log(payload)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-ebics-bank", payload)
    .then(response => {
        console.log(response)
        createBankDialog.value = false
        toast.add({severity:'success', summary: 'Bank hinzugefügt', detail: 'Die Bank wurde erfolgreich hinzugefügt', life: 3000})
        get_base_data()
    })
    .catch(error => {
        console.log(error)
        toast.add({severity:'error', summary: 'Fehler', detail: 'Die Bank konnte nicht hinzugefügt werden', life: 3000});
    })
}

const create_ebics_ini = (e) => {
    let payload = {
        workflow_id: e.workflow_id
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-ebics-ini", payload)
    .then(response => {
        console.log(response)
        toast.add({severity:'success', summary: 'INI Brief erstellt', detail: 'Der INI Brief wurde erfolgreich erstellt', life: 3000})
        get_base_data()
    })
}

const activate_ebics_keys = (e) => {
    let payload = {
        workflow_id: e.workflow_id
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/activate-ebics-keys", payload)
    .then(response => {
        console.log(response)
        toast.add({severity:'success', summary: 'Schlüssel wurden erfolgreich aktiviert', detail: 'Die EBICS Schlüssel wurden für den User erfolgreich aktiviert', life: 3000})
        get_base_data()
    })
}

const fetch_transaction_data = (e) => {
    let payload = {
        workflow_id: e.workflow_id
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/fetch-transaction-data", payload)
    .then(response => {
        console.log(response)
        CoreRouter.push({name: 'Sprachtechnik-Transactions', query: {workflow_id: e.workflow_id}})
    })
}

const view_transaction_details = (e) => {
    transaction.value = e
    viewTransactionDialog.value = true
}

const view_matching_details = (e) => {
    matching.value = e.matching
    transaction.value = e
    viewMatchingDialog.value = true
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const count_unmatched = (value) => {
    return value.filter(transaction => !transaction.matching).length
}

const round_floats = (value) => {
    // Math.round and fix floats to 2 decimals
    return Math.round(value * 100) / 100    
}

const search_invoice = (unmatched_transaction) => {
    console.log(unmatched_transaction)
    transaction.value = unmatched_transaction
    search_city.value = ""
    search_first_name.value = ""
    search_last_name.value = ""
    search_reference.value = ""
    search_street.value = ""
    search_zip.value = ""
    invoice_search_results.value = []
    search_results.value = []
    multiple_invoice_amounts.value = {}
    view_invoice_search_slider.value = true
}

const search_by_name = () => {
    loader.value = true
    let payload = {
        first_name: search_first_name.value,
        last_name: search_last_name.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/invoice-search-by-name", payload)
    .then(response => {
        console.log(response)
        search_results.value = response.data
        loader.value = false
    })
}

const search_by_contact = (e) => {
    console.log("search by contact", e)
    loader.value = true
    let payload = {
        id: e.data.id
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/invoice-search-by-contact", payload)
    .then(response => {
        console.log(response)
        invoice_search_results.value = response.data
        loader.value = false
    })

}

const match_invoice_manually = (invoice) => {
    console.log("match invoice manually", invoice, transaction.value)
    confirm.require({
        message: 'Bist du sicher, dass du die Transaktion manuell verknüpfen möchtest?',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Transaktion verknüpfen!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            let payload = {
                workflow_id: transaction.value.workflow_id,
                invoice_id: invoice.id,
                amount: round_floats(transaction.value.amount).toFixed(2),
                payment_reference: transaction.value.payment_reference,
                reference: transaction.value.reference,
                booking_date: transaction.value.booking_date
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/match-invoice-manually", payload)
            .then(response => {
                console.log(response)
                toast.add({severity:'success', summary: 'Rechnung verknüpft', detail: 'Die Rechnung wurde erfolgreich mit der Transaktion verknüpft', life: 3000})
                get_base_data()
                view_invoice_search_slider.value = false
            })
        }
    })
}

const delete_matching = (payment_id, reference_id, invoice_id, workflow_id) => {
    confirm.require({
        message: 'Bist du sicher, dass du das Matching löschen möchtest?',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Matching löschen!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            console.log("delete matching", payment_id, reference_id, invoice_id, workflow_id)
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/delete-matching-and-payment", {payment_id: payment_id, reference: reference_id, invoice_id: invoice_id, workflow_id: workflow_id})
            .then(response => {
                toast.add({severity:'success', summary: 'Matching gelöscht', detail: 'Das Matching wurde erfolgreich gelöscht', life: 3000})
                get_base_data()
                viewMatchingDialog.value = false
                loader.value = false
            })
        }
    })
}

const create_extra_rate_and_match = (unmatched_transaction, contact) => {
    console.log("create extra rate and match", unmatched_transaction, contact)
    confirm.require({
        message: 'Bist du sicher, dass du eine neue Rate erstellen und die Transaktion verknüpfen möchtest?',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Rate erstellen und Transaktion verknüpfen!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            let payload = {
                workflow_id: unmatched_transaction.workflow_id,
                amount: round_floats(unmatched_transaction.amount),
                payment_reference: unmatched_transaction.payment_reference,
                reference: unmatched_transaction.reference,
                contact_id: contact.id,
                booking_date: unmatched_transaction.booking_date,
                user_id: contact.user_id,
                title: unmatched_transaction.rate_title
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-extra-rate-and-match", payload)
            .then(response => {
                console.log(response)
                toast.add({severity:'success', summary: 'Rate erstellt und Transaktion verknüpft', detail: 'Die Rate wurde erfolgreich erstellt und die Transaktion verknüpft', life: 3000})
                view_invoice_search_slider.value = false
                get_base_data()
            })
        }
    })
}

const calculate_multiple_invoices = () => {
    let total_amount = 0
    let total_invoices = 0
    for (let key in multiple_invoice_amounts.value){
        if (multiple_invoice_amounts.value[key] > 0) {
            total_amount += multiple_invoice_amounts.value[key]
            total_invoices += 1
            console.log("adding value", multiple_invoice_amounts.value[key], total_amount)
        }
    }
    return {count: total_invoices, amount: round_floats(total_amount)}
}

const create_multiple_invoice_payments = (unmatched_transaction, multiple_invoice_overpaid) => {
    console.log("create multiple invoice payments", multiple_invoice_amounts.value, unmatched_transaction)
    confirm.require({
        message: 'Bist du sicher, dass du mehere mehere Zahlungen verknüpfen möchtest?',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, mehere Zahlungen verknüpfen!',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            let payload = {
                workflow_id: unmatched_transaction.workflow_id,
                amount: round_floats(unmatched_transaction.amount),
                payment_reference: unmatched_transaction.payment_reference,
                reference: unmatched_transaction.reference,
                booking_date: unmatched_transaction.booking_date,
                title: "Zu viel bezahlt",
                overpaid: multiple_invoice_overpaid,
                multiple_invoice_amounts: multiple_invoice_amounts.value
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-multiple-payments-and-match", payload)
            .then(response => {
                console.log(response)
                toast.add({severity:'success', summary: 'Rate erstellt und Transaktion verknüpft', detail: 'Mehere Zahlungen wurden erstellt und mit die Transaktion verknüpft', life: 3000})
                view_invoice_search_slider.value = false
                get_base_data()
            })
        }
    })
}

watch ([filter_transactions.value], (currentValue, oldValue) => {
    if (filter_transactions.value.global.value.length > 0){
        console.log("filtering", filter_transactions.value.global.value, expandedRows.value)
    }
})


</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<style>
    .p-timeline-event-opposite {
        flex: 0;
    }
    .p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
        padding-left: 0;
    }
</style>
<template>
    <Toast />
    <ConfirmDialog style="width: 800px" />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <TabView class="col-12" ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-database mr-2"></i>
                <span class="mr-3">Banktransaktionen</span>
            </template>
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText style="width: 400px" v-model="filter_transactions['global'].value" placeholder="Suche" />
                    </span>
                    <Dropdown @change="set_transactions_range()" id="transaction_range" v-model="transaction_range" :options="transaction_ranges" optionLabel="name" optionValue="value" class="mr-3" />
                    <Button @click="view_unmatched_transactions_slider = true" label="Manuell abgleichen" icon="pi pi-link" severity="primary" :badge="String(unmatched_transactions.length)" badgeSeverity="danger" />
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
                <DataTable v-model:expandedRows="expandedRows" :value="transactions" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column expander style="width: 5rem" />
                    <Column field="booking_date">
                        <template #body="slotProps">
                            <Avatar :label="String(slotProps.data.transactions.length)" class="bg-primary text-white mr-2" shape="circle" @click="slotProps.data" v-tooltip.top="'Anzahl Transaktionen'" />
                            <Avatar v-if="count_unmatched(slotProps.data.transactions) > 0" :label="String(count_unmatched(slotProps.data.transactions))" class="bg-red-500 text-white" shape="circle" @click="slotProps.data" v-tooltip.top="'Nicht verknüpfte Transaktionen'" />
                            <Avatar v-if="count_unmatched(slotProps.data.transactions) == 0" icon="pi pi-check" class="bg-green-500 text-white" shape="circle" v-tooltip.top="'Alle Transaktionen sind verknüpft'" />
                        </template>
                    </Column>
                    <Column field="booking_date" header="Datum">
                        <template #body="slotProps">
                            <span class="font-bold">{{format_swiss_date(slotProps.data.booking_date)}}</span>
                        </template>
                    </Column>
                    <Column field="total_amount" header="Total">
                        <template #body="slotProps">
                            <span>{{format_currency(slotProps.data.total_amount)}}</span>
                        </template>
                    </Column>
                    <Column field="account_bank" header="Bank">
                        <template #body="slotProps">
                            <span>{{slotProps.data.account_bank}}</span>
                        </template>
                    </Column>
                    <Column field="account_iban" header="IBAN">
                        <template #body="slotProps">
                            <span>{{slotProps.data.account_iban}}</span>
                        </template>
                    </Column>
                    <Column field="account_description" header="Kontonamen">
                        <template #body="slotProps">
                            <span>{{slotProps.data.account_description}}</span>
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-3 bg-blue-50 rounded shadow-2">
                            <DataTable v-model:filters="filter_transactions" :value="slotProps.data['transactions']" :rows="50" responsiveLayout="scroll" :rowHover="true" class="rounded mt-2">
                                <Column field="booking_date" header="Status">
                                    <template #body="slotProps">
                                        <Avatar v-if="!slotProps.data.matching" icon="pi pi-times" class="bg-red-500 text-white" shape="circle" />
                                        <Avatar v-if="slotProps.data.matching" icon="pi pi-check" class="bg-green-500 text-white" shape="circle" />
                                        <span hidden>{{format_currency(slotProps.data.amount)}}</span>
                                    </template>
                                </Column>
                                <Column field="debitor_name" header="Namen">
                                    <template #body="slotProps">
                                        <span>{{slotProps.data.debitor_name}}</span>
                                    </template>
                                </Column>
                                <Column field="payment_reference" header="Referenz">
                                    <template #body="slotProps">
                                        <span>{{slotProps.data.payment_reference}}</span>
                                    </template>
                                </Column>
                                <Column field="amount" header="Status">
                                    <template #body="slotProps">
                                        <span class="font-bold">{{format_currency(slotProps.data.amount)}}</span>
                                    </template>
                                </Column>
                                <Column field="status" header="Status">
                                    <template #body="slotProps">
                                        <Button v-if="slotProps.data.matching" :label="slotProps.data.matching.invoice_nr" class="mr-2 p-button-success" icon="pi pi-link" @click="view_matching_details(slotProps.data)" />
                                        <Button v-if="slotProps.data.matching" @click="open_url('https://office.bexio.com/index.php/kb_invoice/show/id/' + String(slotProps.data.matching.invoice_id))" class="p-button-rounded mr-2" style="background-color: #0d2f3b; color: #bbdc00" icon="pi pi-eye" v-tooltip.top="'Rechnung in Bexio öffnen'" />
                                        <Button v-if="!slotProps.data.matching" label="Details" class="mr-2" icon="pi pi-info-circle" @click="view_transaction_details(slotProps.data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-cog mr-2"></i>
                <span class="mr-3">Konten</span>
            </template>
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText style="width: 400px" v-model="filter_banks['global'].value" placeholder="Suche" />
                    </span>
                    <Button label="Neue Bank hinzufügen" @click="createBankDialog = true" class="w-auto mr-3 p-button-primary" icon="pi pi-plus" />
                    <Button class="p-button-rounded p-button-text p-button-primary" icon="pi pi-info-circle" @click="infoSnappyBankingDialog = true" />
                </template>
            </Toolbar>
            <DataTable class="mt-4" v-model:filters="filter_banks" :value="banks" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="bankname" header="Bankname">
                    <template #body="slotProps">
                        <Chip class="pl-0 pr-3 mr-2">
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-user" /></span>
                            <span class="ml-2 font-medium" v-tooltip.top="slotProps.data.hostid">{{slotProps.data.bankname}}</span>
                        </Chip>
                    </template>
                </Column>
                <Column field="userid" header="Benutzer">
                    <template #body="slotProps">
                        <span>{{slotProps.data.username}}</span>
                        <Badge class="ml-2 mb-1" :value="slotProps.data.userid" v-tooltip.top="'Teilnehmer-ID'"></Badge>
                    </template>
                </Column>
                <Column field="protocol" header="Protokoll">
                    <template #body="slotProps">
                        <span>{{get_protocol(slotProps.data.protocol)}}</span>
                    </template>
                </Column>
                <Column field="status" header="Status">
                    <template #body="slotProps">
                        <Badge class="bg-gray-700 text-white" v-if="!slotProps.data.status" value="nicht verbunden" v-tooltip.top="'Verbindungsstatus'"></Badge>
                        <Badge class="bg-orange-700 text-white" v-if="slotProps.data.status == 'ini'" value="INI Brief erstellt" v-tooltip.top="'Verbindungsstatus'"></Badge>
                        <Badge class="bg-green-700 text-white" v-if="slotProps.data.status == 'active'" value="Konto Aktiv" v-tooltip.top="'Verbindungsstatus'"></Badge>
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <Button v-if="!slotProps.data.status" label="INI/HIA Brief erstellen" class="mr-2" icon="pi pi-upload" @click="create_ebics_ini(slotProps.data)" />
                        <Button v-if="!slotProps.data.status" class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" @click="delete_bank(slotProps.data)" />
                        <Button v-if="slotProps.data.status == 'ini'" label="INI/HIA Brief ausdrucken" class="mr-2 bg-orange-700 text-white border-orange-800" icon="pi pi-file-pdf" @click="open_url(slotProps.data.ini_letter.replace('cdn-neuraxis-impaxis-io', 'cdn.neuraxis.impaxis.io'))" />
                        <Button v-if="slotProps.data.status == 'ini'" label="Schlüssel aktivieren" class="mr-2" icon="pi pi-check" @click="activate_ebics_keys(slotProps.data)" />
                        <Button v-if="slotProps.data.status == 'active'" label="Transactionen ansehen" class="mr-2" icon="pi pi-database" @click="fetch_transaction_data(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </TabPanel>
    </TabView>
    <!----------------------------------------------->
    <!--------------- View Matching ----------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="viewMatchingDialog" :style="{width: '800px'}" header="Matchingdetails" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="font-bold">Rechnungsnummer</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold text-primary cursor-pointer" @click="open_url('https://office.bexio.com/index.php/kb_invoice/show/id/' + String(matching.invoice_id))">{{ matching.invoice_nr }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Rechnungsbetrag</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ format_currency(matching.invoice_amount) }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Kunde</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold text-primary cursor-pointer" @click="open_url('https://office.bexio.com/index.php/kb_contact/show/id/' + String(matching.contact_id))">{{ matching.contact_nr }} - {{ matching.contact_name }} ({{matching.contact_nr}})</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Rechnungsreferenz</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ matching.transaction_payment_reference }}</span>
            </div>
            <div class="field col-12 mt-3">
                <span class="font-bold text-lg text-lg">Bank-Transaktionsdetails</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktionsreferenz</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.reference }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktionsbetrag</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.currency }} {{ transaction.amount }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktion Debitor-Name</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.debitor_name }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktionsreferenz</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.payment_reference }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktion Debitor Adresse</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.debitor_address }} ({{transaction.debitor_country}})</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktion Debitor IBAN</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.debitor_iban }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktion Bank Name</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.bank_name }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktion Bank Adresse</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.bank_address }}</span>
            </div>
            <div class="field col-6 mt-3">
                <span class="font-bold">Transaktion Bank BIC</span>
            </div>
            <div class="field col-6 mt-3">
                <span>{{ transaction.bank_bic }}</span>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Matching löschen" class="p-button-danger" @click="delete_matching(matching.payment_id, transaction.reference, matching.invoice_id, transaction.workflow_id)" />
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------->
    <!--------------- View Transaction ----------------->
    <!-------------------------------------------------->
    <Dialog v-model:visible="viewTransactionDialog" :style="{width: '1000px'}" header="Transaktion" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="reference" v-model="transaction.reference" />
                    <label for="reference">Referenz</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="currency" v-model="transaction.currency" />
                    <label for="currency">Währung</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="amount" v-model="transaction.amount" />
                    <label for="amount">Betrag</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="type" v-model="transaction.type" />
                    <label for="type">Typ</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="bank_description" v-model="transaction.bank_description" />
                    <label for="bank_description">Bank Beschreibung</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="payment_reference" v-model="transaction.payment_reference" />
                    <label for="payment_reference">Zahlungsreferenz</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="debitor_name" v-model="transaction.debitor_name" />
                    <label for="debitor_name">Debitor Name</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="debitor_country" v-model="transaction.debitor_country" />
                    <label for="debitor_country">Debitor Land</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="debitor_address" v-model="transaction.debitor_address" />
                    <label for="debitor_address">Debitor Adresse</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="ultimate_debitor" v-model="transaction.ultimate_debitor" />
                    <label for="ultimate_debitor">Endgültiger Zahlungspflichtiger</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="ultimate_debitor_adresse" v-model="transaction.ultimate_debitor_adresse" />
                    <label for="ultimate_debitor_adresse">Endgültiger Zahlungspflichtiger Adresse</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="debitor_iban" v-model="transaction.debitor_iban" />
                    <label for="debitor_iban">Debitor IBAN</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="bank_name" v-model="transaction.bank_name" />
                    <label for="bank_name">Bank Name</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="bank_bic" v-model="transaction.bank_bic" />
                    <label for="bank_bic">Bank BIC</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText :disabled="true" id="bank_address" v-model="transaction.bank_address" />
                    <label for="bank_address">Bank Adresse</label>
                </span>
            </div>
        </div>
    </Dialog>
    <!-------------------------------------------------->
    <!--------------- Edit/Add Bank -------------------->
    <!-------------------------------------------------->
    <Dialog v-model:visible="createBankDialog" :style="{width: '1000px'}" header="Bank hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <InlineMessage severity="warn">Die folgende Bankdaten finden Sie unter die Bankparameterdaten Blatt</InlineMessage>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown @change="set_hostid" id="bankname" v-model="bankname" :options="banknames" optionLabel="name" optionValue="value" />
                    <label for="bankname">Bank name</label>
                </span>
                <small class="ml-3 text-gray-500">Bankname</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="hostid" v-model="hostid" />
                    <label for="hostid">EBICS Host-ID</label>
                </span>
                <small class="ml-3 text-gray-500">8-stellige EBICS ID</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="bankname" v-model="protocol" :options="protocols" optionLabel="name" optionValue="value" />
                    <label for="bankname">Protocol</label>
                </span>
                <small class="ml-3 text-gray-500">Transferverfahren</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="url" v-model="url" />
                    <label for="url">EBICS URL</label>
                </span>
                <small class="ml-3 text-gray-500">https:// Adresse</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="username" v-model="username" />
                    <label for="username">Username</label>
                </span>
                <small class="ml-3 text-gray-500">Namen oder Beschreibung</small>
            </div>
            <div class="field col-6 mt-3"></div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="userid" v-model="userid" />
                    <label for="userid">User-ID</label>
                </span>
                <small class="ml-3 text-gray-500">EBICS Teilnehmer-ID</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="partnerid" v-model="partnerid" />
                    <label for="partnerid">Partner-ID</label>
                </span>
                <small class="ml-3 text-gray-500">EBICS Vertrags-ID</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="passphrase" v-model="passphrase" />
                    <label for="passphrase">Passphrase</label>
                </span>
                <small class="ml-3 text-gray-500">Passwort für die Zertifikatsverschlüsselung</small>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="keyversion" v-model="keyversion" :options="keyversions" optionLabel="name" optionValue="value" />
                    <label for="keyversion">Verschlüsselung</label>
                </span>
                <small class="ml-3 text-gray-500">EBICS-Bankschlüssel Version</small>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="!bankname || !keyversion || !protocol || !passphrase || !hostid || !url || !partnerid || !userid || !banks || !username ? true : false" label="Bank speichern" @click="create_bank()" class="mr-2 w-auto" icon="pi pi-save" />
                <Button label="Abbrechen" @click="createBankDialog = false" class="mr-2 w-auto p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="infoSnappyBankingDialog" :style="{width: '800px'}" header="Snappy Banking" :modal="true" class="p-fluid">
        <div>
            <Timeline :value="infoSnappyBanking" class="customized-timeline">
                <template #marker="slotProps">
                    <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 bg-primary">
                        {{ slotProps.index + 1 }}
                    </span>
                </template>
                <template #content="slotProps">
                    <Card class="mt-3 ml-2">
                        <template #title>
                            <h4>{{ slotProps.item.title }}</h4>
                        </template>
                        <template #subtitle>
                            <p>{{ slotProps.item.subtitle }}</p>
                        </template>
                        <template #content>
                            <p>{{ slotProps.item.content }}</p>
                        </template>
                    </Card>
                </template>
            </Timeline>
        </div>        
    </Dialog>
    <!-------------------------------------------------->
    <!----------- Manual Matching Slider --------------->
    <!-------------------------------------------------->
    <Sidebar header="Transaktionen manuel abgleichen" v-model:visible="view_unmatched_transactions_slider" position="left" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <Accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus" class="mt-3">
                    <AccordionTab v-for="(unmatched_transaction, key) in unmatched_transactions" :key="key" >
                        <template #header>
                            <span class="flex align-items-center gap-2 w-full">
                                <span class="font-bold white-space-nowrap mr-2">{{format_swiss_date(unmatched_transaction.booking_date)}} - {{ unmatched_transaction.debitor_name }}</span>
                                <Badge :value="format_currency(unmatched_transaction.amount)" class="mr-2" />
                            </span>
                        </template>
                        <Button label="Details" class="mr-2" icon="pi pi-info-circle" @click="view_transaction_details(unmatched_transaction)" />
                        <Button label="Rechnung suchen" class="mr-2 p-button-success" icon="pi pi-search" @click="search_invoice(unmatched_transaction)" />
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </Sidebar>
    <!-------------------------------------------------->
    <!------------ Invoice Search Slider --------------->
    <!-------------------------------------------------->
    <Sidebar header="Rechnung suchen" v-model:visible="view_invoice_search_slider" position="right" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <div class="p-3 bg-blue-50 rounded shadow-2 flex flex-wrap">
                    <div class="mr-2 white-space-nowrap">
                        <span class="font-bold mr-2">Betrag:</span><span>{{ transaction.currency }} {{ transaction.amount }}</span>
                    </div>
                    <div class="mr-2 white-space-nowrap">
                        <span class="font-bold mr-2">Debitor-Name:</span><span>{{ transaction.debitor_name }}</span>
                    </div>
                    <div class="mr-2 white-space-nowrap">
                        <span class="font-bold mr-2">Referenz:</span><span>{{ transaction.payment_reference }}</span>
                    </div>
                    <div class="mr-2 white-space-nowrap">
                        <span class="font-bold mr-2">Debitor-Adresse:</span><span>{{ transaction.debitor_address }} ({{transaction.debitor_country}})</span>
                    </div>
                </div>
                <div class="p-3 border-200 rounded shadow-2 mt-3">
                    <TabView>
                        <TabPanel header="Nach Namen suchen">
                            <div class="col-12 formgrid grid p-3">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputText id="first_name" v-model="search_first_name" class="w-full" />
                                        <label for="first_name">Vornamen</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputText id="last_name" v-model="search_last_name" class="w-full" />
                                        <label for="last_name">Nachnamen</label>
                                    </span>
                                </div>
                                <div class="field col-12">
                                    <Button :disabled="search_first_name == '' || search_last_name == '' ? false : true" label="Suchen" class="mr-2" icon="pi pi-search" @click="search_by_name()" />
                                    <Button label="Zurücksetzen" class="mr-2 p-button-text" icon="pi pi-refresh" @click="search_first_name = '', search_last_name = ''" />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Nach Adresse suchen">
                            <div class="col-12 formgrid grid p-3">
                                <div class="field col-5">
                                    <span class="p-float-label">
                                        <InputText id="street" v-model="search_street" class="w-full" />
                                        <label for="street">Strasse</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputText id="zip" v-model="search_zip" class="w-full" />
                                        <label for="zip">PLZ</label>
                                    </span>
                                </div>
                                <div class="field col-3">
                                    <span class="p-float-label">
                                        <InputText id="city" v-model="search_city" class="w-full" />
                                        <label for="city">Ort</label>
                                    </span>
                                </div>
                                <div class="field col-12">
                                    <Button label="Suchen" class="mr-2" icon="pi pi-search" />
                                    <Button label="Zurücksetzen" class="mr-2 p-button-text" icon="pi pi-refresh" />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Nach Referenz suchen">
                            <div class="col-12 formgrid grid p-3">
                                <div class="field col-12">
                                    <span class="p-float-label">
                                        <InputText id="reference" v-model="search_reference" class="w-full" />
                                        <label for="reference">Referenz</label>
                                    </span>
                                </div>
                                <div class="field col-12">
                                    <Button label="Suchen" class="mr-2" icon="pi pi-search" />
                                    <Button label="Zurücksetzen" class="mr-2 p-button-text" icon="pi pi-refresh" />
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
                <div v-if="search_results" class="p-3 border-200 rounded shadow-2 mt-3">
                    <DataTable v-model:expandedRows="expandedRows" @rowExpand="search_by_contact" :value="search_results" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column expander style="width: 5rem" />
                        <Column field="nr" header="Kd-Nr.">
                            <template #body="slotProps">
                                <span class="font-bold">{{slotProps.data.nr}}</span>
                            </template>
                        </Column>
                        <Column field="name_2" header="Vornamen">
                            <template #body="slotProps">
                                <span>{{slotProps.data.name_2}}</span>
                            </template> 
                        </Column>
                        <Column field="name_1" header="Nachnamen">
                            <template #body="slotProps">
                                <span>{{slotProps.data.name_1}}</span>
                            </template>
                        </Column>
                        <Column field="address" header="Strasse">
                            <template #body="slotProps">
                                <span>{{slotProps.data.address}}</span>
                            </template>
                        </Column>
                        <Column field="postcode" header="PLZ">
                            <template #body="slotProps">
                                <span>{{slotProps.data.postcode}}</span>
                            </template>
                        </Column>
                        <Column field="city" header="Ort">
                            <template #body="slotProps">
                                <span>{{slotProps.data.city}}</span>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div class="p-3 bg-blue-50 rounded shadow-2">
                                <div>
                                    Rechnungen von: <span class="font-bold mr-4">{{slotProps.data.name_1}} {{slotProps.data.name_2}} ({{slotProps.data.nr}})</span>
                                    <span v-if="!transaction['overpaid'] || transaction['multiple_invoices']" @click="transaction['overpaid'] = true, transaction['multiple_invoices'] = false" class="p-3 font-bold text-primary cursor-pointer">+ zuv. bez. KG erstellen</span>
                                    <span v-if="!transaction['multiple_invoices'] || transaction['overpaid']" @click="transaction['multiple_invoices'] = true, transaction['overpaid'] = false" class="p-3 font-bold text-primary cursor-pointer">+ Betrag aufteilen</span>
                                </div>
                                <div v-if="transaction['overpaid']" class="p-3 rounded shadow-3 border-200 mt-3 mb-3 ml-1 col-12 formgrid grid">
                                    <div class="field col-4 mt-3">
                                        <span class="p-float-label">
                                            <InputText id="title" type="text" v-model="transaction.rate_title" class="w-full" />
                                            <label for="title">Rechnungs-Titel (Optional)</label>
                                        </span>
                                    </div>
                                    <div class="field col-4 mt-3">
                                        <span class="p-float-label">
                                            <InputNumber disabled id="amount" mode="currency" currency="CHF" locale="de-CH" v-model="transaction.amount" class="w-full" />
                                            <label for="amount">Betrag</label>
                                        </span>
                                    </div>
                                    <div class="field col-4 mt-3">
                                        <Button label="zuv. bez. KG erstellen" @click="create_extra_rate_and_match(transaction, slotProps.data)" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
                                    </div>
                                </div>
                                <DataTable :value="invoice_search_results" class="mt-2">
                                    <Column field="is_valid_to" header="Fälligkeit">
                                        <template #body="slotProps">
                                            <span>{{format_swiss_date(slotProps.data.is_valid_to)}}</span>
                                        </template>
                                    </Column>
                                    <Column field="total" header="Betrag">
                                        <template #body="slotProps">
                                            <span>{{format_currency(slotProps.data.total)}}</span>
                                        </template>
                                    </Column>
                                    <Column field="kb_item_status_id" header="Status">
                                        <template #body="slotProps">
                                            <Badge v-if="slotProps.data.kb_item_status_id == 7" value="Entwurf" class="bg-gray-700 text-white" />
                                            <Badge v-if="slotProps.data.kb_item_status_id == 8" value="Offen" />
                                            <Badge v-if="slotProps.data.kb_item_status_id == 16" :value="'Teilbezahlt / Offen: ' + String(slotProps.data.total_remaining_payments)" class="bg-orange-700 text-white" />
                                            <Badge v-if="slotProps.data.kb_item_status_id == 9" value="Bezahlt" class="bg-green-700 text-white" />
                                        </template>
                                    </Column>
                                    <Column>
                                        <template #body="slotProps">
                                            <Button v-if="round_floats(slotProps.data.total) == round_floats(transaction.amount) && slotProps.data.kb_item_status_id == 8 || round_floats(slotProps.data.total) == round_floats(transaction.amount) && slotProps.data.kb_item_status_id == 7" label="Rechnung verknüpfen" icon="pi pi-link" @click="match_invoice_manually(slotProps.data)" />
                                            <InputNumber v-if="transaction['multiple_invoices'] && slotProps.data.kb_item_status_id == 8 || transaction['multiple_invoices'] && slotProps.data.kb_item_status_id == 16" v-model="multiple_invoice_amounts[slotProps.data.id]" :max="round_floats(slotProps.data.total_remaining_payments)" mode="currency" currency="CHF" locale="de-CH" />
                                        </template>
                                    </Column>
                                </DataTable>
                                <div v-if="transaction['multiple_invoices']" class="p-3 bg-blue-50 rounded shadow-2">
                                    <div class="col-12 formgrid grid mt-3">
                                        <div class="field col-4">
                                            <span class="p-float-label">
                                                <InputNumber disabled id="total_invoices" :modelValue="calculate_multiple_invoices()['count']" class="w-auto" />
                                                <label for="total_invoices">Anzahl Rechnungen</label>
                                            </span>
                                        </div>
                                        <div class="field col-4">
                                            <span class="p-float-label">
                                                <InputNumber disabled id="total_amount" :modelValue="calculate_multiple_invoices()['amount'] + multiple_invoice_overpaid" class="w-auto" mode="currency" currency="CHF" locale="de-CH" />
                                                <label for="total_amount">Total Betrag</label>
                                            </span>
                                        </div>
                                        <div class="field col-4">
                                            <span class="p-float-label">
                                                <InputNumber id="overpaid_amount" v-model="multiple_invoice_overpaid" class="w-auto" mode="currency" currency="CHF" locale="de-CH" />
                                                <label for="overpaid_amount">Zu viel bezahlt</label>
                                            </span>
                                        </div>
                                        <div class="field col-12">
                                            <Button :disabled="calculate_multiple_invoices()['amount'] + multiple_invoice_overpaid != round_floats(transaction.amount)" @click="create_multiple_invoice_payments(transaction, multiple_invoice_overpaid)" label="Teilzahlungen ausführen" class="w-auto p-button-success" icon="pi pi-save" />
                                        </div>
                                        <div v-if="calculate_multiple_invoices()['amount'] != round_floats(transaction.amount)" class="field col-12">
                                            <span class="text-red-700 mr-2">Gesamtbetrag stimmt nicht mit dem Überweisungsbetrag überein:</span><span class="font-bold">{{format_currency(round_floats(transaction.amount) - (calculate_multiple_invoices()['amount'] + round_floats(multiple_invoice_overpaid)))}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </Sidebar>
</template>