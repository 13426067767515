<script setup>
import { ref, onMounted, watch, defineProps } from 'vue'
import axios from 'axios'


const print_data = ref()

onMounted( async () => {
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/print-bank-statement", {id: props.print_param})
    .then(response => {
        print_data.value = response.data
    })
})


const props = defineProps({
    print_param: { type: String }
})

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
</script>

<template>
    <div v-if="!print_data">
        Druckdaten werden geladen...
    </div>
    <div v-if="print_data" class="col-12 formgrid grid">
        <div class="field col-12 mt-3">
            <img :src="'data:image/png;base64, '+ print_data.logo_base64" />
        </div>
        <div class="field col-4 col-offset-8">
            {{print_data.contact_name}} <br />
            {{print_data.contact_address}} <br />
            {{print_data.contact_postcode}} {{print_data.contact_city}}<br />
            {{print_data.contact_mail}}<br />
            <strong>No:</strong> {{print_data.contact_nr}}<br />
        </div>
        <div class="field col-12">
            <DataTable class="mb-6" :value="print_data.statement_header">
                <Column field="paid" header="Total Bezahlt">
                    <template #body="slotProps">
                        {{slotProps.data.paid}}
                    </template>
                </Column>
                <Column field="unpaid" header="Total Offen">
                    <template #body="slotProps">
                        {{slotProps.data.unpaid}}
                    </template>
                </Column>
                <Column field="unpaid_overdue" header="Total Überfällig">
                    <template #body="slotProps">
                        {{slotProps.data.unpaid_overdue}}
                    </template>
                </Column>
            </DataTable>
        </div>
        <div class="field col-12">
            <DataTable class="mt-6 w-full" :value="print_data.invoices" :rows="100">
                <Column field="is_valid_from" header="Rechnungs-Datum">
                    <template #body="slotProps">
                        {{format_swiss_date(slotProps.data.is_valid_from)}}
                    </template>
                </Column>
                <Column field="document_nr" header="Rechnungs-Nr">
                    <template #body="slotProps">
                        {{slotProps.data.document_nr}}
                    </template>
                </Column>
                <Column field="is_valid_to" header="Fällig bis">
                    <template #body="slotProps">
                        {{format_swiss_date(slotProps.data.is_valid_to)}}
                    </template>
                </Column>
                <Column field="total" header="Betrag">
                    <template #body="slotProps">
                        {{format_currency(slotProps.data.total)}}
                    </template>
                </Column>
                <Column field="total_received_payments" header="Bezahlt">
                    <template #body="slotProps">
                        <span v-if="Number(slotProps.data.total_received_payments) > 0">{{format_currency(slotProps.data.total_received_payments)}}</span>
                        <span v-if="Number(slotProps.data.total_credit_vouchers) > 0">-{{format_currency(slotProps.data.total_credit_vouchers)}}</span>
                    </template>
                </Column>
                <Column field="total_remaining_payments" header="Offen">
                    <template #body="slotProps">
                        {{format_currency(slotProps.data.total_remaining_payments)}}
                    </template>
                </Column>
                <Column header="Zahlungsdatum">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.kb_item_status_id != 8">{{format_swiss_date(slotProps.data.payments[0].date)}}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>