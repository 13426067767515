<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { AnyTypeAnnotation } from '@babel/types'

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()

// filters
const filter_lead = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

//////////////////
// Data Sources //
//////////////////

const leads = ref()
const users = ref()
const products = ref()
const selected_product = ref()
const bank_accounts = ref()
const fictional_users = ref()
const salutations = ref()
const languages = ref()

const get_base_data = () => {
    // Get Zugänge
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-leads')
    .then(response => {
        leads.value = response.data
        })

    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/dashboard/get-users')
    .then(response => {
        users.value = response.data
        })

    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-all-bexio-products')
    .then(response => {
        products.value = response.data
        })

    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-bank-accounts')
    .then(response => {
        bank_accounts.value = response.data
        })

    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/get-fictional-users')
    .then(response => {
        fictional_users.value = response.data
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/bexio-salutations')
    .then(response => {
        salutations.value = response.data
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/bexio-languages')
    .then(response => {
        languages.value = response.data
    })
}

onMounted(() => {
    get_base_data()
})

/////////////////
// Form Fields //
/////////////////

const lead = ref({
    id: {
        name: "ID",
        required: false,
        validation: "text",
        message: "Bitte ID ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    order_name: {
        name: "Auftragsname",
        required: true,
        validation: "text",
        message: "Bitte Auftragsname ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    product: {
        name: "Produkt",
        required: true,
        validation: "text",
        message: "Bitte Produkt auswählen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    product_name: {
        name: "Produktnamen",
        required: false,
        validation: "text",
        message: "Bitte Produktnamen auswählen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    product_description: {
        name: "Produktbeschreibung",
        required: false,
        validation: "text",
        message: "Bitte Produktbeschreibung ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    product_code: {
        name: "Produkt Code",
        required: false,
        validation: "text",
        message: "Bitte Produkt Code ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    product_price: {
        name: "Bruttopreis",
        required: true,
        validation: "text",
        message: "Bitte Preis ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    sale_price: {
        name: "Nettopreis",
        required: true,
        validation: "text",
        message: "Bitte Verkaufspreis ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    rates: {
        name: "Raten",
        required: true,
        validation: "text",
        message: "Bitte Raten auswählen",
        value: 1,
        error: false,
        active: true,
        unique: false
    },
    invoice_amount: {
        name: "Rechnungsbetrag",
        required: true,
        validation: "text",
        message: "Bitte Rechnungsbetrag auswählen",
        value: 1,
        error: false,
        active: true,
        unique: false
    },
    order_date: {
        name: "Anmeldung vom",
        required: true,
        validation: "text",
        message: "Bitte \"Anmeldung vom\" Datum setzen",
        value: 1,
        error: false,
        active: true,
        unique: false
    },
    commission_date: {
        name: "Provisiondatum",
        required: true,
        validation: "text",
        message: "Bitte Provisionsdatum setzen",
        value: 1,
        error: false,
        active: true,
        unique: false
    },
    delivery_date: {
        name: "Lieferdatum",
        required: true,
        validation: "text",
        message: "Bitte Lieferdatum setzen",
        value: 1,
        error: false,
        active: true,
        unique: false
    },
    discount: {
        name: "Teilzahlungszuschlag",
        required: false,
        validation: "text",
        message: "Bitte Teilzahlungszuschlag setzen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    discount_percent: {
        name: "Skonto %",
        required: false,
        validation: "text",
        message: "Bitte Skonto setzen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    discount_cash: {
        name: "Skonto",
        required: false,
        validation: "text",
        message: "Bitte Skonto setzen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    bank_account: {
        name: "Konto",
        required: true,
        validation: "text",
        message: "Bitte Konto auswählen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    language: {
        name: "Sprache",
        required: false,
        validation: "text",
        message: "Bitte Sprache definieren",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    birthday: {
        name: "Geburtsdatum",
        required: true,
        validation: "text",
        message: "Bitte Geburtsdatum definieren",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    vertreter: {
        name: "Vertreter",
        required: true,
        validation: "text",
        message: "Bitte Vertreter asuwählen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    name_1: {
        name: "Nachname",
        required: true,
        validation: "text",
        message: "Bitte Nachname ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    name_2: {
        name: "Vorname",
        required: true,
        validation: "text",
        message: "Bitte Vorname ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    address: {
        name: "Adresse",
        required: true,
        validation: "text",
        message: "Bitte Adresse ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    postcode: {
        name: "PLZ",
        required: true,
        validation: "text",
        message: "Bitte PLZ ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    city: {
        name: "Ort",
        required: true,
        validation: "text",
        message: "Bitte Ort ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    mail: {
        name: "E-Mail",
        required: true,
        validation: "text",
        message: "Bitte E-Mail ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    phone_fixed: {
        name: "Telefon",
        required: true,
        validation: "text",
        message: "Bitte Telefon ausfüllen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    salutation_id: {
        name: "Anrede",
        required: true,
        validation: "text",
        message: "Bitte Anrede auswählen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    language_id: {
        name: "Kunden Sprache",
        required: true,
        validation: "text",
        message: "Bitte Sprache auswählen",
        value: 0,
        error: false,
        active: true,
        unique: false
    },
    first_rate_months: {
        name: "1. Rate nach",
        required: true,
        validation: "text",
        message: "Bitte 1. Rate in Monaten auswählen",
        value: 1,
        error: false,
        active: true,
        unique: false
    }
})

const edit = ref({
    "product_name": null,
    "Produktbeschreibung": null,
    "product_price": null,
    "sale_price": null,
    "discount": null,
    "discount_percent": null,
    "rates": null,
    "invoice_amount": null,
    "order_name": null,
    "order_date": null,
    "commission_date": null,
    "delivery_date": null,
    "bank_account": null,
    "birthday": null,
    "order_position": null,
    "added_positions": [],
    "vertreter": "",
    "name_1": "",
    "name_2": "",
    "address": "",
    "postcode": "",
    "city": "",
    "mail": "",
    "phone_fixed": "",
    "salutation_id": 0,
    "language_id": 0,
    "first_rate_months": 1
})

const first_rate_months = ref([
    {
        "id": 1,
        "name": "1 Monat"
    },
    {
        "id": 2,
        "name": "2 Monate"
    },
    {
        "id": 3,
        "name": "3 Monate"
    }
])

const positions = ref([])

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// CRUD Logic //////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createOrderDialog = ref(false)
const newLeadDialog = ref(false)
const invoice_rate_math = ref()
const selected_custom_product = ref()
const positions_total = ref()
const initial_product_price = ref()
const new_lead_firstname = ref()
const new_lead_lastname = ref()
const new_lead_email = ref()

const new_lead = () => {
    newLeadDialog.value = true
}

const onCellEditComplete = (event) => {
    let { newData, index } = event
    positions.value[index] = newData
    positions_total.value = getPositionsTotal()
    lead.value.sale_price.value = initial_product_price.value + positions_total.value
}

const addCustomProduct = () => {
    positions.value.push({
        text: selected_custom_product.value.intern_name + " (" + selected_custom_product.value.intern_code + ")",
        amount: 1,
        unit_price: parseFloat(selected_custom_product.value.sale_price).toFixed(2)
    })
    positions_total.value = getPositionsTotal()
    lead.value.sale_price.value = initial_product_price.value + positions_total.value
}

const getPositionsTotal = () => {
    let total = 0
    positions.value.forEach((position) => {
        total += parseFloat(position.unit_price) * position.amount
    })
    return total
}

const save_new_lead = () => {
    loader.value = true
    if(new_lead_firstname.value && new_lead_lastname.value && new_lead_email.value) {
        let payload = {"firstname": new_lead_firstname.value, "lastname": new_lead_lastname.value, "email": new_lead_email.value}
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-lead", payload)
            .then(response => {
                toast.add({severity:'success', summary: 'Anfrage hinzugefügt', detail: new_lead_firstname.value + " " + new_lead_lastname.value, life: 2000})
                newLeadDialog.value = false
                leads.value = response.data
                loader.value = false
            })
            .catch(error => {
            console.error("There was an error!", error.message);
        });
    }
    else
    {
        toast.add({severity:'error', summary: 'Fehler', detail: 'Bitte Vor- und Nachname eingeben', life: 2000})
    }
}

const saveOrder = () => {
    if(validate_form(lead.value)){
        // set all values to edit object
        //createOrderDialog.value = false
        edit.value.product_name = lead.value.product_name.value
        edit.value.product_price = lead.value.product_price.value
        edit.value.sale_price = lead.value.sale_price.value
        edit.value.order_position = lead.value.sale_price.value - positions_total.value
        edit.value.discount = lead.value.discount.value
        edit.value.rates = lead.value.rates.value
        edit.value.invoice_amount = lead.value.invoice_amount.value
        edit.value.order_date = swissDate(lead.value.order_date.value)
        edit.value.order_name = lead.value.order_name.value
        edit.value.language = lead.value.language.value
        edit.value.commission_date = swissDate(lead.value.commission_date.value)
        edit.value.delivery_date = swissDate(lead.value.delivery_date.value)
        edit.value.discount_cash = lead.value.discount_cash.value
        edit.value.discount_percent = lead.value.discount_percent.value
        edit.value.bank_account = lead.value.bank_account.value
        edit.value.birthday = lead.value.birthday.value
        edit.value.added_positions = positions.value
        edit.value.product_description = lead.value.product_description.value
        edit.value.vertreter = lead.value.vertreter.value["id"]
        edit.value.name_1 = lead.value.name_1.value
        edit.value.name_2 = lead.value.name_2.value
        edit.value.address = lead.value.address.value
        edit.value.postcode = lead.value.postcode.value
        edit.value.city = lead.value.city.value
        edit.value.mail = lead.value.mail.value
        edit.value.phone_fixed = lead.value.phone_fixed.value
        edit.value.salutation_id = lead.value.salutation_id.value
        edit.value.language_id = lead.value.language_id.value
        edit.value.first_rate_months = lead.value.first_rate_months.value
        console.log("edit", edit.value)
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sprachtechnik/create-order-invoices", edit.value)
            .then(response => {
                toast.add({severity:'success', summary: 'Auftrag und Rechnungen wurden erfolgreich erstellt', detail: edit.value.order_name, life: 2000})
            }).catch(error => {
            console.error("There was an error!", error.message);
        });
        reset_obj_values(lead.value)
    }
}

const setPrice = (product) => {
    lead.value.rates.value = 1
    lead.value.product.value = product.id
    for (let key in products.value)
        if(products.value[key]["id"] == product.id){
            lead.value.sale_price.value = parseInt(products.value[key]["sale_price"])
            initial_product_price.value = parseInt(products.value[key]["sale_price"])
            lead.value.product_name.value = products.value[key]["intern_name"] + " (" + products.value[key]["intern_code"] + ")"
            lead.value.product_description.value = products.value[key]["intern_description"]
            lead.value.discount.value = 0
            lead.value.discount_percent.value = 0
            lead.value.product_price.value = lead.value.sale_price.value
            positions_total.value = 0
            setRates(36)
        }
}

const setRates = (id) => {
    invoice_rate_math.value = String(id) + " x"
    lead.value.product_price.value = lead.value.sale_price.value + lead.value.discount.value
    lead.value.discount_cash.value = lead.value.sale_price.value - Math.round((lead.value.sale_price.value * lead.value.discount_percent.value / 100))
    if(id == 1){
        lead.value.invoice_amount.value = lead.value.sale_price.value
    }
    else{
        lead.value.invoice_amount.value = (lead.value.product_price.value / id)
    }
}

const createOrder = (edit) => {
    reset_obj_values(lead.value)
    lead.value.first_rate_months.value = 1
    lead.value.name_1.value = edit.lastname
    lead.value.name_2.value = edit.firstname
    lead.value.address.value = edit.street
    lead.value.postcode.value = edit.zip
    lead.value.city.value = edit.city
    lead.value.mail.value = edit.email
    lead.value.phone_fixed.value = edit.phone
    lead.value.salutation_id.value = null
    lead.value.language_id.value = edit.customer_language
    lead.value.order_name.value = edit.firstname + " " + edit.lastname
    lead.value.language.value = edit.customer_language
    lead.value.vertreter.value = null
    positions.value = []
    createOrderDialog.value = true
}

const validate_form = (obj) => {
    let valid = true
    for (let key in obj)
        // check if required fields are empty
        if(!obj[key].value && obj[key].required && obj[key].active){
            valid = false
            obj[key].error = true
            toast.add({severity:'error', summary: 'Pflichtfelder nicht ausgefüllt', detail: obj[key].message, life: 2000})
        }
        else
        {
            obj[key].error = false
        }
    return valid
}

const reset_obj_values = (obj) => {
    for (let key in obj){
        obj[key].value = ""
        obj[key].error = false
    }
    return obj
}

const user_name = (id, users) => {
    let user_name
    for (let key in users)
        if(users[key].id == id){
            user_name = users[key]["fullname"]
        }
    return user_name
}

const get_bexio_rep = (rep, users, fictional_users) => {
    let rep_id
    for (let key in users)
        if(users[key].id == rep){
            for (let key2 in fictional_users)
                if(fictional_users[key2].mail == users[key].email){
                    rep_id = fictional_users[key2]["id"]
                }
        }
    return rep_id
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const get_swiss_date = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return String(day).padStart(2, '0') + "." + String(month).padStart(2, '0') + "." + year
}

const open_url = (url) => {
    window.open(url, '_blank')
}

function swissDate(date) {
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = '' + d.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('.');
}

// watch values in lead object
watch([lead.value], () => 
    setRates(lead.value.rates.value)
)
</script>

<style scoped>
.p-inputtext {
    opacity: 1!important;
}
.p-multiselect-token  {
    color: #ffffff!important;
    background-color: #2196F3;
}
.p-datatable-header {
    padding: 0px!important;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_lead['global'].value" placeholder="Suche" style="width: 400px" />
            </span>
            <Button label="Anfrage hinzufügen" @click="new_lead()" class="w-auto mr-1" type="button" icon="pi pi-plus" v-tooltip.right="'Anfrage manuell hinzufügen'" />
        </template>
        <template #end>
            <Button label="Leads exportieren"  @click="open_url('https://api.snpy.ch/sprachtechnik/export-leads')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Leads exportieren'" />
        </template>
    </Toolbar>
    <DataTable class="mt-4" v-model:filters="filter_lead" :value="leads" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true">
        <Column field="firstname" header="Vorname" sortable>
            <template #body="slotProps">
                <Avatar icon="pi pi-google" class="mr-2" style="background-color:#2196F3; color: #ffffff" shape="circle" />
                {{slotProps.data.firstname}}
            </template>
        </Column>
        <Column field="lastname" header="Nachname" sortable>
            <template #body="slotProps">
                {{slotProps.data.lastname}}
            </template>
        </Column>
        <Column field="email" header="E-Mail"></Column>
        <Column field="street" hidden />
        <Column field="zip" hidden />
        <Column field="city" hidden />
        <Column field="phone" hidden />
        <Column field="fullname" hidden />
        <Column field="fullname_reverse" hidden />
        <Column field="dc" header="Eingetragen" sortable>
            <template #body="slotProps">
                {{swissDate(slotProps.data.dc)}}
            </template>
        </Column>
        <Column header="">
            <template #body="slotProps">
                <Button label="Auftrag erstellen" @click="createOrder(slotProps.data)" class="w-auto mr-2 mb-1" icon="pi pi-file" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!--------------------------------------------------->
    <!----------------- Create Order -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="createOrderDialog" :style="{width: '850px'}" header="Auftrag erstellen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="lead.order_name.value" :class="{'p-invalid':lead.order_name.error}" />
                    <label for="name">{{lead.order_name.name}}</label>
                </span>
                <small v-if="(lead.order_name.error)" class="p-error">{{lead.order_name.message}}</small>
            </div>
            <div class="field col-12 mt-3">
                <Dropdown id="product" placeholder="Produkt auswählen" @change="setPrice(selected_product)" v-model="selected_product" optionLabel="intern_code" :options="products" :filter="true" :class="{'p-invalid':lead.product.error}">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            {{slotProps.value.intern_name}} ({{slotProps.value.intern_code}})
                        </div>
                        <div v-else>
                            {{slotProps.placeholder}}
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div>
                            {{slotProps.option.intern_name}} ({{slotProps.option.intern_code}})
                        </div>
                    </template>
                </Dropdown>
                <small v-if="(lead.product.error)" class="p-error">{{lead.product.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-12 mt-3">
                <Accordion class="accordion-custom">
                    <AccordionTab>
                        <template #header>
                            <span>Zusätzliche Positionen</span>
                        </template>
                        <DataTable :reorderableColumns="true" editMode="cell" @cell-edit-complete="onCellEditComplete" :value="positions" :rows="5" responsiveLayout="scroll" :rowHover="true">
                            <div class="col-12 formgrid grid">
                                <div class="field col-8">
                                    <Dropdown placeholder="Produkt auswählen" v-model="selected_custom_product" optionLabel="intern_code" :options="products" :filter="true">
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value">
                                                {{slotProps.value.intern_name}} ({{slotProps.value.intern_code}})
                                            </div>
                                            <div v-else>
                                                {{slotProps.placeholder}}
                                            </div>
                                        </template>
                                        <template #option="slotProps">
                                            <div>
                                                {{slotProps.option.intern_name}} ({{slotProps.option.intern_code}})
                                            </div>
                                        </template>
                                    </Dropdown>
                                </div>
                                <div class="field col-4">
                                    <Button :disabled="selected_custom_product ? false : true" label="Produkt hinzufügen" @click="addCustomProduct()" class="w-auto" icon="pi pi-plus" />
                                </div>
                            </div>
                            <Column field="text" header="Produkt" style="width:75%">
                                <template #editor="{ data, field }">
                                    <InputText style="width:100%;"  v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column field="amount" header="Anzahl" style="width:10%">
                                <template #editor="{ data, field }">
                                    <InputText style="width:100%;"  v-model="data[field]" autofocus />
                                </template>
                            </Column>
                            <Column field="unit_price" header="Preis" style="width:15%">
                                <template #editor="{ data, field }">
                                    <InputText style="width:100%;"  v-model="data[field]" autofocus />
                                </template>
                            </Column>
                        </DataTable>
                        <div v-if="positions_total" class="col-12 formgrid grid ml-2 mt-3 font-bold">
                            Total: {{format_currency(positions_total)}}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="sale_price" v-model="lead.sale_price.value" mode="currency" currency="CHF" locale="de-CH" :inputStyle="{'p-invalid':lead.sale_price.error}" tabindex="0" />
                    <label for="sale_price">{{lead.sale_price.name}}</label>
                </span>
                <small v-if="(lead.sale_price.error)" class="p-error">{{lead.sale_price.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="discount" v-model="lead.discount.value" mode="currency" currency="CHF" locale="de-CH" :inputStyle="{'p-invalid':lead.discount.error}" tabindex="1" />
                    <label for="discount">{{lead.discount.name}}</label>
                </span>
                <small v-if="(lead.discount.error)" class="p-error">{{lead.discount.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="discount_percent" v-model="lead.discount_percent.value" suffix="%" :inputStyle="{'p-invalid':lead.discount_percent.error}" tabindex="2" />
                    <label for="discount_percent">{{lead.discount_percent.name}}</label>
                </span>
                <small v-if="(lead.discount_percent.error)" class="p-error">{{lead.discount_percent.message}}</small>
            </div>
                <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="discount_cash" v-model="lead.discount_cash.value" mode="currency" currency="CHF" locale="de-CH" :inputStyle="{'p-invalid':lead.discount_cash.error}" />
                    <label for="discount_cash">{{lead.discount_cash.name}}</label>
                </span>
                <small v-if="(lead.discount_cash.error)" class="p-error">{{lead.discount_cash.message}}</small>
            </div>                       
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="product_price" v-model="lead.product_price.value" mode="currency" currency="CHF" locale="de-CH" :inputStyle="{'p-invalid':lead.product_price.error}" />
                    <label for="product_price">{{lead.product_price.name}}</label>
                </span>
                <small v-if="(lead.product_price.error)" class="p-error">{{lead.product_price.message}}</small>
            </div>
            <div v-if="lead.product_price.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputNumber id="rates" v-model="lead.rates.value" mode="decimal" :step="1" showButtons :min="1" :max="36" class="neuraxis_inputnumber" />
                    <label for="rates">{{lead.rates.name}}</label>
                </span>
                <small v-if="(lead.rates.error)" class="p-error">{{lead.rates.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-inputgroup">
                    <span class="p-inputgroup-addon" style="min-width: 60px;">{{invoice_rate_math}}</span>
                    <InputNumber id="invoice_amount" v-model="lead.invoice_amount.value" mode="currency" currency="CHF" locale="de-CH" :inputStyle="{'p-invalid':lead.invoice_amount.error}" />
                </span>
                <small v-if="(lead.invoice_amount.error)" class="p-error">{{lead.invoice_amount.message}}</small>
            </div>
            <div v-if="lead.product_price.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="order_date" dateFormat="dd.mm.yy" v-model="lead.order_date.value" :showIcon="true" :class="{'p-invalid':lead.order_date.error}" />
                    <label for="order_date">{{lead.order_date.name}}</label>
                </span>
                <small v-if="(lead.order_date.error)" class="p-error">{{lead.order_date.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="delivery_date" dateFormat="dd.mm.yy" v-model="lead.delivery_date.value" :showIcon="true" :class="{'p-invalid':lead.delivery_date.error}" />
                    <label for="delivery_date">{{lead.delivery_date.name}}</label>
                </span>
                <small v-if="(lead.delivery_date.error)" class="p-error">{{lead.delivery_date.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="commission_date" dateFormat="dd.mm.yy" v-model="lead.commission_date.value" :showIcon="true" :class="{'p-invalid':lead.commission_date.error}" />
                    <label for="commission_date">{{lead.commission_date.name}}</label>
                </span>
                <small v-if="(lead.commission_date.error)" class="p-error">{{lead.commission_date.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="first_rate" v-model="lead.first_rate_months.value" :options="first_rate_months" optionLabel="name" optionValue="id" :class="{'p-invalid':lead.first_rate_months.error}" />
                    <label for="first_rate">{{lead.first_rate_months.name}}</label>
                </span>
                <small v-if="(lead.first_rate_months.error)" class="p-error">{{lead.first_rate_months.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="vertreter" placeholder="Vertreter auswählen" v-model="lead.vertreter.value" :options="fictional_users" optionLabel="id" :class="{'p-invalid':lead.vertreter.error}">
                        <template #value="slotProps">
                            <div v-if="slotProps.value">
                                {{slotProps.value.firstname}} {{slotProps.value.lastname}}
                            </div>
                            <div v-else>
                                {{slotProps.placeholder}}
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div>
                                {{slotProps.option.firstname}} {{slotProps.option.lastname}}
                            </div>
                        </template>
                    </Dropdown>
                    <label for="vertreter">{{lead.vertreter.name}}</label>
                </span>
                <small v-if="(lead.vertreter.error)" class="p-error">{{lead.vertreter.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown id="bank_account" placeholder="Konto auswählen" v-model="lead.bank_account.value" :options="bank_accounts" optionValue="id" optionLabel="name" :class="{'p-invalid':lead.bank_account.error}" />
                    <label for="bank_account">{{lead.bank_account.name}}</label>
                </span>
                <small v-if="(lead.bank_account.error)" class="p-error">{{lead.bank_account.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name_2" type="text" v-model="lead.name_2.value" :class="{'p-invalid':lead.name_2.error}" />
                    <label for="name_2">{{lead.name_2.name}}</label>
                </span>
                <small v-if="(lead.name_2.error)" class="p-error">{{lead.name_2.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name_1" type="text" v-model="lead.name_1.value" :class="{'p-invalid':lead.name_1.error}" />
                    <label for="name_1">{{lead.name_1.name}}</label>
                </span>
                <small v-if="(lead.name_1.error)" class="p-error">{{lead.name_1.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-8 mt-3">
                <span class="p-float-label">
                    <InputText id="address" type="text" v-model="lead.address.value" :class="{'p-invalid':lead.address.error}" />
                    <label for="address">{{lead.address.name}}</label>
                </span>
                <small v-if="(lead.address.error)" class="p-error">{{lead.address.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-4 mt-3">
                <span class="p-float-label">
                    <InputText id="postcode" type="text" v-model="lead.postcode.value" :class="{'p-invalid':lead.postcode.error}" />
                    <label for="postcode">{{lead.postcode.name}}</label>
                </span>
                <small v-if="(lead.postcode.error)" class="p-error">{{lead.postcode.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="city" type="text" v-model="lead.city.value" :class="{'p-invalid':lead.city.error}" />
                    <label for="city">{{lead.city.name}}</label>
                </span>
                <small v-if="(lead.city.error)" class="p-error">{{lead.city.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="birthday" dateFormat="dd.mm.yy" v-model="lead.birthday.value" :showIcon="true" :class="{'p-invalid':lead.birthday.error}" />
                    <label for="birthday">{{lead.birthday.name}}</label>
                </span>
                <small v-if="(lead.birthday.error)" class="p-error">{{lead.birthday.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="mail" type="text" v-model="lead.mail.value" :class="{'p-invalid':lead.mail.error}" />
                    <label for="mail">{{lead.mail.name}}</label>
                </span>
                <small v-if="(lead.mail.error)" class="p-error">{{lead.mail.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="phone_fixed" type="text" v-model="lead.phone_fixed.value" :class="{'p-invalid':lead.phone_fixed.error}" />
                    <label for="phone_fixed">{{lead.phone_fixed.name}}</label>
                </span>
                <small v-if="(lead.phone_fixed.error)" class="p-error">{{lead.phone_fixed.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="salutation" v-model="lead.salutation_id.value" :options="salutations" optionLabel="name" optionValue="id" :class="{'p-invalid':lead.salutation_id.error}" />
                    <label for="salutation_id">{{lead.salutation_id.name}}</label>
                </span>
                <small v-if="(lead.salutation_id.error)" class="p-error">{{lead.salutation_id.message}}</small>
            </div>
            <div v-if="lead.product.value" class="field col-6 mt-3">
                <span class="p-float-label">
                    <Dropdown id="language_id" v-model="lead.language_id.value" :options="languages" optionLabel="name" optionValue="id" :class="{'p-invalid':lead.language_id.error}" />
                    <label for="language_id">{{lead.language_id.name}}</label>
                </span>
                <small v-if="(lead.language_id.error)" class="p-error">{{lead.language_id.message}}</small>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Auftrag & Rechnungen erstellen" @click="saveOrder()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="createOrderDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------ Create Lead -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="newLeadDialog" :style="{width: '650px'}" header="Anfrage manuell hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="Lead" type="text" v-model="new_lead_firstname" required />
                    <label for="Lead">Vorname</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="Lead" type="text" v-model="new_lead_lastname" required />
                    <label for="Lead">Nachname</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="Lead" type="text" v-model="new_lead_email" required />
                    <label for="Lead">E-Mail</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Speichern" @click="save_new_lead()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newLeadDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
</template>