<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import Products from "./Sprachtechnik-Statistics-Products.vue"
import Orders from "./Sprachtechnik-Statistics-Orders.vue"
import Reports from "./Sprachtechnik-Statistics-Reports.vue"
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
</script>
<template>
    <Toast />
    <ConfirmDialog />
    <div class="p-fluid formgrid grid">
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
            <template #header>
                <i class="pi pi-table mr-2"></i>
                <span class="mr-3">Aufträge</span>
            </template>
            <Orders />
            </TabPanel>
            <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Vertreterabrechnungen</span>
            </template>
            <Reports />
            </TabPanel>
            <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Produkten</span>
            </template>
            <Products />
            </TabPanel>
        </TabView>
    </div>
</template>