/** 
 * Index for default customer 
*/
/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/no-reserved-component-names */
import { createApp} from 'vue'
import App from '@/App.vue'
import CoreRouter from '@/core/router/router';
import DataHub from '@/core/components/User/Admin/Admin-Datahub.vue';
import Settings from '@/core/components/User/Admin/Admin-User-Settings.vue'
import Customers from './components/Sprachtechnik-Customers.vue';
import Leads from './components/Sprachtechnik-Leads.vue';
import Statistics from './components/Sprachtechnik-Statistics.vue';
import CourseMaker from './components/Sprachtechnik-Course-Maker.vue';
import Banking from './components/Sprachtechnik-Ebics.vue';
import Print from './components/Sprachtechnik-Print.vue';
import Students from './components/Students/Sprachtechnik-Students.vue';

import {
    Square3Stack3DIcon,
    DocumentIcon
} from '@heroicons/vue/24/outline';
import {
    RouteAdd,
    RouteAddtoDashboard,
    DashboardAddLink
} from '@/core/var/router';
const customer = require('./config/config');
import '@/core/var/axios';
import { createPinia } from 'pinia'; const pinia = createPinia();
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import '@/core/assets/primevue/neuraxis.css';
import '/node_modules/primevue/resources/themes/mira/theme.css'


import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import InputMask from 'primevue/inputmask'
import Toast from 'primevue/toast'
import Breadcrumb from 'primevue/breadcrumb'
import Menubar from 'primevue/menubar'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Panel from 'primevue/panel'
import OrderList from 'primevue/orderlist'
import AutoComplete from 'primevue/autocomplete'
import OverlayPanel from 'primevue/overlaypanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Row from 'primevue/row'
import ColumnGroup from 'primevue/columngroup'
import Fieldset from 'primevue/fieldset'
import Divider from 'primevue/divider'
import Card from 'primevue/card'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Ripple from 'primevue/ripple'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import ProgressSpinner  from 'primevue/progressspinner'
import BlockUI from 'primevue/blockui'
import Chips from 'primevue/chips'
import StyleClass from 'primevue/styleclass'
import Textarea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import TreeSelect from 'primevue/treeselect'
import InputSwitch from 'primevue/inputswitch'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Chip from 'primevue/chip'
import Checkbox from 'primevue/checkbox'
import Tag from 'primevue/tag'
import SpeedDial from 'primevue/speeddial'
import Menu from 'primevue/menu'
import Sidebar from 'primevue/sidebar'
import Message from 'primevue/message'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import RadioButton from 'primevue/radiobutton'
import SelectButton from 'primevue/selectbutton'
import ConfirmDialog from 'primevue/confirmdialog'
import FileUpload from 'primevue/fileupload'
import ToggleButton from 'primevue/togglebutton'
import TreeTable from 'primevue/treetable'
import Editor from 'primevue/editor'
import SplitButton from 'primevue/splitbutton'
import Toolbar from 'primevue/toolbar'
import Galleria from 'primevue/galleria'
import InlineMessage from 'primevue/inlinemessage'
import Chart from 'primevue/chart'
import Image from 'primevue/image'
import Timeline from 'primevue/timeline'


RouteAddtoDashboard({ name: 'Statistiken', position: 'dashboard-left' }, Statistics, CoreRouter)
RouteAddtoDashboard({ name: 'Kunden', position: 'dashboard-left' }, Customers, CoreRouter)
RouteAddtoDashboard({ name: 'Interessenten', position: 'dashboard-left' }, Leads, CoreRouter)
RouteAddtoDashboard({ name: 'Studenten', position: 'dashboard-left' }, Students, CoreRouter)
RouteAddtoDashboard({ name: 'Einstellungen', position: 'dashboard-left' }, Settings, CoreRouter)
RouteAddtoDashboard({ name: 'Banking', position: 'dashboard-left' }, Banking, CoreRouter)
RouteAddtoDashboard({ name: 'Course-Maker', position: 'dashboard-left' }, CourseMaker, CoreRouter)


const data_hub_link = 'datahub';
RouteAdd(data_hub_link, DataHub, CoreRouter);
DashboardAddLink({ name: 'Data Hub', href: `/${data_hub_link}`, icon: Square3Stack3DIcon, order: 10 });
const sprachtechnik_print_link = 'print'
RouteAdd(sprachtechnik_print_link, Print, CoreRouter)


// set customerdata for templates

const neuraxisApp = createApp(App)
neuraxisApp.use(CoreRouter)
neuraxisApp.provide('CoreRouter', CoreRouter)

neuraxisApp.use(pinia)

const customerStore = useCustomerStore()
customerStore.setCustomer(customer)

const auth = useAuthStore()


const service_addr = 'https://sprachtechnik-gs5cjqwj7a-oa.a.run.app/'
const SERVICE_URL = process.env.NODE_ENV !== 'development' ? `${service_addr}/preview` : 'http://localhost:3000/preview'
neuraxisApp.provide('SERVICE_URL', SERVICE_URL)

//optional
neuraxisApp.use(PrimeVue, {ripple: true})
neuraxisApp.use(ToastService)
neuraxisApp.use(ConfirmationService)
neuraxisApp.directive('Tooltip', Tooltip)
neuraxisApp.directive('badge', BadgeDirective)
neuraxisApp.directive('ripple', Ripple)
neuraxisApp.directive('styleclass', StyleClass)

neuraxisApp.component('Dialog', Dialog)
neuraxisApp.component('InputText', InputText)
neuraxisApp.component('Button', Button)
neuraxisApp.component('Toast', Toast)
neuraxisApp.component('ConfirmDialog', ConfirmDialog)
neuraxisApp.component('Breadcrumb', Breadcrumb)
neuraxisApp.component('Menubar', Menubar)
neuraxisApp.component('Splitter', Splitter)
neuraxisApp.component('SplitterPanel', SplitterPanel)
neuraxisApp.component('Panel', Panel)
neuraxisApp.component('OrderList', OrderList)
neuraxisApp.component('AutoComplete', AutoComplete)
neuraxisApp.component('OverlayPanel', OverlayPanel)
neuraxisApp.component('DataTable', DataTable)
neuraxisApp.component('Column', Column)
neuraxisApp.component('Row', Row)
neuraxisApp.component('ColumnGroup', ColumnGroup)
neuraxisApp.component('Fieldset', Fieldset)
neuraxisApp.component('Divider', Divider)
neuraxisApp.component('Card', Card)
neuraxisApp.component('Badge', Badge)
neuraxisApp.component('Dropdown', Dropdown)
neuraxisApp.component('MultiSelect', MultiSelect)
neuraxisApp.component('ProgressSpinner', ProgressSpinner)
neuraxisApp.component('BlockUI', BlockUI)
neuraxisApp.component('InputNumber', InputNumber)
neuraxisApp.component('Chips', Chips)
neuraxisApp.component('Textarea', Textarea)
neuraxisApp.component('Calendar', Calendar)
neuraxisApp.component('TreeSelect', TreeSelect)
neuraxisApp.component('InputSwitch', InputSwitch)
neuraxisApp.component('TabView', TabView)
neuraxisApp.component('TabPanel', TabPanel)
neuraxisApp.component('Avatar', Avatar)
neuraxisApp.component('AvatarGroup', AvatarGroup)
neuraxisApp.component('Chip', Chip)
neuraxisApp.component('Checkbox', Checkbox)
neuraxisApp.component('InputMask', InputMask)
neuraxisApp.component('Tag', Tag)
neuraxisApp.component('SpeedDial', SpeedDial)
neuraxisApp.component('Menu', Menu)
neuraxisApp.component('Sidebar', Sidebar)
neuraxisApp.component('Message', Message)
neuraxisApp.component('Accordion', Accordion)
neuraxisApp.component('AccordionTab', AccordionTab)
neuraxisApp.component('RadioButton', RadioButton)
neuraxisApp.component('SelectButton', SelectButton)
neuraxisApp.component('FileUpload', FileUpload)
neuraxisApp.component('ToggleButton', ToggleButton)
neuraxisApp.component('TreeTable', TreeTable)
neuraxisApp.component('Editor', Editor)
neuraxisApp.component('SplitButton', SplitButton)
neuraxisApp.component('Toolbar', Toolbar)
neuraxisApp.component('Galleria', Galleria)
neuraxisApp.component('InlineMessage', InlineMessage)
neuraxisApp.component('Chart', Chart)
neuraxisApp.component('Image', Image)
neuraxisApp.component('Timeline', Timeline)

neuraxisApp.mount('#app');
